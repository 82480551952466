import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "iStock-494152704.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data.hero.childImageSharp.fluid}
      style={{
        position: "absolute",
        top: 160,
        bottom: 0,
        left: 0,
        right: 0,
      }}
      imgStyle={{
        objectPosition: "center top",
        opacity: 0.9,
      }}
    />
  )
}

export default Image

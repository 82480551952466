import React from "react"
import PropTypes from "prop-types"
import Image from "./image"

import "./layout.css"

const Layout = ({ children }) => {
  return (
    <div
      style={{
        minHeight: `100vh`,
        position: "relative",
      }}
    >
      <Image />
      <main
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: 640,
          textAlign: `center`,
          position: "relative",
          zIndex: "10",
          padding: `80px 16px`,
        }}
      >
        {children}
      </main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
